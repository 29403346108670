import React from "react";
import Carousel from "./Carousel";



function About() { 

    return (
        <div>
            <div className="about-container">
		        <div className="container-text">
			        <h2 style={{ display: 'inline' }} id="about">About Me</h2>
			        <p className="bio" >Hello, and thank you for taking the time to check out my site. I am a six year Air Force veteran where I spent 2 and a half years in Misawa Japan and 3 and a half years in San Antonio Texas. After my contract was up I decided to pursue a career in web development. I was able to attend Full Stack Academy thanks to some of my veterans benefits. During this course I developed skills in HTML5, CSS, SQL, and Javascript including React and Node.  <br /> <br /> I am currently looking for new opportunities in the Council Bluffs, Iowa area and am open to remote positions. If you have any questions for me or would like to talk otherwise see my contact page on how to reach me.</p>
                </div>
                <Carousel />
            </div>
        </div>
    )
}

export default About