import React from 'react'
function Home() {
    return (
        <section id='home-main'>
			    <img className='self-image' src="./images/My_pic.jpeg" alt="me at the Tower of the Americas" />
            <div className='home-content'>
                <h2 className="info-header">Steven Kelly</h2>
                <h4 className="info">New to the world of web development. Excited to put my new knowledge into practice. Based in Council Bluffs, IA</h4>
            </div>
        </section>
    )
}

export default Home