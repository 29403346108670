import React from "react";

function Contact() {
    return (
        <div id="contact">
            <h1>Contact Me!</h1>
            <h3>
                Email me at <a id='email' href="mailto: st.kelly098@gmail.com">st.kelly098@gmail.com</a> or check out my socials down below.
            </h3>
                <a href="https://www.linkedin.com/in/skelly98/"  target="_blank">
                    <img id="linkedin" src='./images/linkedin_black.png'></img>
                </a>

                <a href="https://github.com/Skelly9898" target="_blank">
                    <img id="github" src="./images/github.webp"></img>
                </a>

                <a href="https://www.facebook.com/steve.kelly.376695/" target="_blank">
                    <img id="facebook" src="./images/facebook_black.png"></img>
                </a>
        </div>
    )
}

export default Contact