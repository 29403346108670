import React from 'react';
import { Route, Routes, Link } from 'react-router-dom'
import About from './about';
import Portfolio from './portfolio';
import Home from './home';
import Contact from './contact';

const App = () => {
    return (
        <div>
            <nav className="side">
                <div className='links'>
                    <Link className="bar link-border" to='/'>Home</Link>
                    <Link className="bar link-border" to='/about'>About</Link>
                    <Link className="bar link-border" to='/projects'>Projects</Link>
                    <Link className='bar' to='/contact'>Contact</Link>
                </div>  
            </nav>
    <Routes>
      <Route path='/' element={<Home />}></Route>
      <Route path='/about' element={<About />}></Route>
      <Route path='/projects' element={<Portfolio />}></Route>
      <Route path='/contact' element={<Contact />}></Route>
    </Routes>
        </div>
    );
};

export default App;