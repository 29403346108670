import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Carousel = () => {
  const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000, // Adjust the speed as needed
    responsive: [
        {
          breakpoint: 1024, // Adjust the breakpoint as needed
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 768, // Adjust the breakpoint as needed
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 480, // Adjust the breakpoint as needed
          settings: {
            slidesToShow: 1,
          },
        },
      ],
  };

  return (
    <div className='carousel-container'>
      <Slider {...settings}>
        <div className='carousel-img-div'>
          <img className='carousel-img' src="./images/misawa.png" alt="Image 1" />
        </div>
        <div className='carousel-img-div'>
          <img className='carousel-img' src="./images/usaf.jpeg" alt="Image 2" />
        </div>
        <div className='carousel-img-div'>
          <img className='carousel-img' src="./images/ft sam.jpeg" alt="Image 3" />
        </div>
        <div className='carousel-img-div'>
          <img className='carousel-img' src="./images/ft sam peacock.jpeg" alt="Image 3" />
        </div>
        <div className='carousel-img-div'>
          <img className='carousel-img' src="./images/usaf steven.jpeg" alt="Image 3" />
        </div>
        <div className='carousel-img-div'>
          <img className='carousel-img' src="./images/ft sam tower.jpeg" alt="Image 3" />
        </div>
        <div className='carousel-img-div'>
          <img className='carousel-img' src="./images/Fullstack_Academy_logo.png" alt="Image 3" />
        </div>
      </Slider>
    </div>
  );
};

export default Carousel;
