import React from "react";

function Portfolio() {
    return (
        <div className="portfolio-page">
            <div className="portfolio-wrapper">
            <h2 style={{ display: 'inline' }} className="project-header">My Projects</h2>
            <div className="project-one">
                <h3 className="first-project"><a className='first-project-link' href="https://github.com/Team-Brooklyn-Sets/BrooklynStreetArt">Brooklyn Street Art</a></h3>
                <p className="my-work">Brooklyn Street Art is the first fullstack project I worked on from beginning to end at Fullstack Academy. My partners and I created an ecommerce site focused on selling digital artwork. We built it using Javascript, React, Redux, Git, Express, and PostgreSQL. I created the front and back end of the sign in and sign up feature. I also worked with my team to build the individual products page.</p>
            </div>
            <div className="project-two">
                <h3><a href="https://github.com/GamingWizzards/Capstone" className="project-two-link">Amissa's Echo</a></h3>
                <p className="my-work">Amissa's Echo is a 2D platformer my team and I built using vanilla Javascript. I was responsible for making the start menu as well as putting the music into the game. I also helped figure out how to put in collision and lethal blocks and worked with each team member to debug a lot of the blockers we ran into. </p>
            </div>
        </div>
        </div>
    )
}

export default Portfolio